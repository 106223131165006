import { Route } from '@angular/router';
import { BaseComponent } from './core/modules/base/components/base/base.component';
import { LoggedInService } from './core/modules/auth/guards/logged-in/logged-in.service';
import { TestComponent } from './modules/test/test/test.component';


export const routes: Route[] = [
    { 
        path: '', 
        component: BaseComponent, 
        loadChildren: () => import('./core/modules/base/base.module').then(m => m.BaseModule),
        canActivate: [LoggedInService]
    },
    { path: 'auth', loadChildren: () => import('./core/modules/auth/auth.module').then(m => m.AuthModule) },
    // { path: 'password-recovery', loadChildren: () => import('./core/modules/password-recovery/password-recovery.module').then(m => m.PasswordRecoveryModule) },
    { path: 'canoe', component: TestComponent },

];

