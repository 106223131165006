import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private AuthService: AuthService
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        const accessToken = this.AuthService.getAccessToken();
        if (accessToken) {
            const accessTokenHeader = 'Bearer ' + accessToken;
            const cloned = request.clone({
                headers: request.headers.set("Authorization", accessTokenHeader)
          });

            return next.handle(cloned);
        }

        else {
            return next.handle(request);
        }

    }

}
